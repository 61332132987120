import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Link,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import useDeepCompareEffect from '../utils/hooks/useDeepCompareEffect';

interface TocItem {
  url: string;
  title: string;
  items?: TocItem[] | undefined;
}

export interface TocProps {
  items: TocItem[];
}

function getIds(items: TocItem[] | undefined): string[] | null {
  if (!items) {
    return null;
  }

  return items.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1));
    }
    if (item.items) {
      const ids = getIds(item.items);
      if (ids) acc.push(...ids);
    }
    return acc;
  }, [] as string[]);
}

function useActiveId(itemIds: string[] | null): string | null {
  if (!itemIds) {
    return null;
  }
  const [activeId, setActiveId] = useState(``);
  useDeepCompareEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries.find(it => it.isIntersecting);
        if (entry) {
          setActiveId(entry.target.id);
        }
      },
      { rootMargin: `0% 0% -80% 0%` },
    );
    itemIds.forEach(id => {
      const htmlItem = document.getElementById(id);
      if (htmlItem) observer.observe(htmlItem);
    });
    return () => {
      itemIds.forEach(id => {
        const htmlItem = document.getElementById(id);
        if (htmlItem) observer.unobserve(htmlItem);
      });
    };
  }, [itemIds]);
  return activeId;
}

function renderItems(
  items: TocItem[],
  activeId: string | null,
  nested: boolean = false,
) {
  return (
    <List ps={nested ? 6 : 0} spacing={3}>
      {items &&
        items.map((item, idx) => (
          <React.Fragment key={item.url + ''}>
            <ListItem>
              {/* 有时标题会跳跃 */}
              {item.title && item.url && (
                <Link
                  variant="now"
                  href={item.url}
                  fontWeight={nested ? 'normal' : 'medium'}
                  fontSize="lg"
                  opacity={activeId === item.url.slice(1) ? '1' : '0.7'}
                >
                  {item.title}
                </Link>
              )}
            </ListItem>
            {item.items && renderItems(item.items, activeId, true)}
          </React.Fragment>
        ))}
    </List>
  );
}

export default function TableOfContents({ items }: TocProps) {
  const headers = getIds(items);
  const activeId = useActiveId(headers);
  const allowToggle = useBreakpointValue({ base: true, md: false });
  
  return (
    <Accordion
      defaultIndex={[0]}
      allowToggle={allowToggle}
      borderColor="transparent"
      minH={[0, 0, 'xl']}
      pos="sticky"
      top={4}
      zIndex="10"
      layerStyle="now"
      boxShadow="primary"
      borderRadius={[0, 0, 'md']}
    >
      <AccordionItem>
        <AccordionButton _hover={undefined}>
          {allowToggle && <AccordionIcon color="white" mx={2} />}
          <Heading flex="1" py={2} textAlign="left" size="md">
            目录
          </Heading>
        </AccordionButton>
        <AccordionPanel pb={4}>{renderItems(items, activeId)}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

