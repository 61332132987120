import { ChatIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Code,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Td,
  Text,
  Textarea,
  Th,
  Tr,
  UnorderedList,
  useColorMode,
  useColorModeValue,
  useToast,
  VStack
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { createElement, Fragment, useEffect, useMemo, useState } from 'react';
import colorModeValue from 'src/utils/colorModeValue';
import moment, { fromNow } from 'src/utils/moment';

import unified from 'unified';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeReact from 'rehype-react';
import { creatHeadering, shortcodes } from 'src/components/PostMDXRenderer';

export interface CommentsProps {
  allCommentsJson?: GatsbyTypes.ShadowedPostPageQuery["allCommentsJson"];
  slug: string;
  postId: string;
}

type CommentsJson = GatsbyTypes.CommentsJsonFieldsFragment & {
  comments: GatsbyTypes.CommentsJsonFieldsFragment[];
};

const mapCommentsProps: (
  commentsJson: CommentsJson,
  slug: string,
  postId: string,
) => CommentProps = (commentsJson, slug, postId) => {
  return {
    postId: postId,
    slug: slug,
    _id: commentsJson._id,
    message: commentsJson.message,
    name: commentsJson.name,
    email: commentsJson.email,
    date: commentsJson.date,
    replyToUser: commentsJson.replyToUser,
    comments: commentsJson.comments?.map(comment =>
      mapCommentsProps(
        comment as CommentsJson,
        slug,
        postId,
      ),
    ),
  };
};

export const mdComponents = {
  h1: ({ children, ...restProps }) =>
    creatHeadering(children, restProps, 'h1', 'md'),
  h2: ({ children, ...restProps }) =>
    creatHeadering(children, restProps, 'h2', 'md', 'normal'),
  h3: ({ children, ...restProps }) =>
    creatHeadering(children, restProps, 'h3', 'sm'),
  code: (props: any) => {
    const bgColor = useColorModeValue('whiteAlpha.300', 'blackAlpha.100');
    return props.className ? (
      <Code
        colorScheme=""
        layerStyle="nowContent"
        bgColor={bgColor}
        display="block"
        whiteSpace="pre"
        overflow="auto"
        p={2}
        my={2}
        borderRadius="md"
        {...props}
      />
    ) : (
      <Code colorScheme="" layerStyle="nowContent" bgColor={bgColor} {...props} />
    );
  },
  table: (props: any) => (
    <TableContainer>
      <Table variant="simple" {...props} />
    </TableContainer>
  ),
  tr: (props: any) => <Tr {...props} />,
  th: (props: any) => <Th {...props} />,
  td: (props: any) => <Td {...props} />,
  ul: (props: any) => <UnorderedList {...props} />,
  ol: (props: any) => <OrderedList {...props} />,
  li: (props: any) => <ListItem {...props} />,
  a: (props: any) => <Link {...props} />,
};

export function Comments({ allCommentsJson, slug, postId }: CommentsProps) {
  const comments = useMemo(() => {
    return allCommentsJson?.nodes.map(commentsJson =>
      mapCommentsProps(commentsJson as any, slug, postId),
    );
  }, [allCommentsJson])
  
  return (
    <Box layerStyle="now" p={4} my={8}>
      <Container maxW="container.xl">
        <Heading size="lg" my={4} id="comment">
          评论
        </Heading>
        {comments && comments.length > 0 && <CommentList comments={comments} />}

        <Divider />

        <Heading size="lg" my={4}>
          新的评论
        </Heading>
        <NewComment slug={slug} postId={postId} />
      </Container>
    </Box>
  );
}

export interface CommentProps {
  _id: string;
  message: string;
  name: string;
  email?: string;
  date: number;
  replyToUser?: string;
  comments: CommentProps[];
  slug: string;
  postId: string;
  nestLevel?: number;
  parentId?: string;
}

export function Comment({
  _id,
  message,
  name,
  email,
  date,
  replyToUser,
  comments,
  slug,
  postId,
  nestLevel = 0,
  parentId,
}: CommentProps) {
  const { colorMode } = useColorMode();
  const [reply, setReply] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(<Fragment />);
  
  useEffect(() => {
    unified()
      .use(remarkParse)
      .use(remarkRehype as any)
      .use(rehypeReact as any, { createElement, components: mdComponents })
      .process(message)
      .then(file => {
        setContent(file.result as React.ReactNode);
      });
  }, []);


  return (
    <>
      <Flex>
        <Avatar
          size="lg"
          name={email ? name : undefined}
          src={
            email ? `https://www.gravatar.com/avatar/${email}` : undefined
          }
        />
        <VStack alignItems="start" ml={4}>
          <HStack>
            <Text fontWeight="semibold">{name}</Text>
            <Text
              fontSize="sm"
              color={colorModeValue(
                colorMode,
                'whiteAlpha.700',
                'blackAlpha.500',
              )}
            >
              {fromNow(moment(date))}
            </Text>
          </HStack>
          <Text>
            {replyToUser && <>回复{replyToUser}: </>}
          </Text>
          <Container p={0}>
            {content}
          </Container>
          <Button
            leftIcon={<ChatIcon />}
            variant="icon"
            size="lg"
            colorScheme={colorModeValue(colorMode, 'pureWhite', 'pureBlack')}
            onClick={() => {
              setReply(true);
            }}
          >
            回复
          </Button>
        </VStack>
      </Flex>

      {reply && (
        <>
          <Divider my={4} />
          <NewComment
            postId={postId}
            slug={slug}
            replyTo={nestLevel < 2 ? _id : parentId}
            replyToUser={name}
            onCancel={() => setReply(false)}
          />
        </>
      )}

      {comments && comments.length > 0 && (
        <CommentList comments={comments} nestLevel={nestLevel + 1} parentId={_id} />
      )}
    </>
  );
}

export interface CommentListProps {
  comments: CommentProps[];
  nestLevel?: number;
  parentId?: string;
}

export function CommentList({ comments, nestLevel = 0, parentId }: CommentListProps) {
  return (
    <UnorderedList styleType="none">
      {comments.map(comment => (
        <ListItem key={comment._id} my={4}>
          <Comment {...comment} nestLevel={nestLevel} parentId={parentId} />
        </ListItem>
      ))}
    </UnorderedList>
  );
}

export interface NewCommentProps {
  slug: string;
  postId: string;
  replyTo?: string;
  replyToUser?: string;
  onCancel?: () => void;
}

export function NewComment({
  slug,
  postId,
  replyTo,
  replyToUser,
  onCancel,
}: NewCommentProps) {
  const { colorMode } = useColorMode();
  const inputErrorColor = useColorModeValue('yellow.300', 'yellow.500');
  const toast = useToast();

  return (
    <Formik
      initialValues={Object.assign(
        {
          'fields[message]': '',
          'fields[name]': '',
          'fields[email]': '',
          'options[slug]': slug.replaceAll('/', '-').replace(/(^-)|(-$)/g, ''),
          'fields[postId]': postId,
        },
        replyTo
          ? { 'fields[replyTo]': replyTo, 'fields[replyToUser]': replyToUser }
          : undefined,
      )}
      onSubmit={async (values, actions) => {
        const response = await fetch(
          `${process.env.GATSBY_STATICMAN_URL}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            mode: 'cors',
            body: Object.keys(values)
              .map(key => key + '=' + encodeURIComponent(values[key]))
              .join('&'),
          },
        );
        if (response.ok) {
          toast({
            title: '评论已提交',
            description: '将在审核通过后可见',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          
          actions.setFieldValue("['fields[message]']", '', false);
        } else {
          toast({
            title: '提交失败',
            description: '请稍后再试',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }

      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form data-testid="comment-form">
          {replyToUser && (
            <Text fontWeight="semibold">回复 {replyToUser} 的评论</Text>
          )}
          <Field
            name="['fields[message]']"
            validate={value => {
              let error;
              if (value === '') {
                error = '消息是必须的';
              }
              return error;
            }}
          >
            {({ field, form }) => (
              <FormControl
                my={3}
                isInvalid={errors['fields[message]'] != undefined}
              >
                <FormLabel htmlFor="message">评论内容*</FormLabel>
                <Textarea
                  {...field}
                  id="message"
                  focusBorderColor="white"
                  errorBorderColor={inputErrorColor}
                  width={['100%', '100%', 'xl']}
                />
                {errors['fields[message]'] && touched['fields[message]'] && (
                  <FormErrorMessage color={inputErrorColor}>
                    {errors['fields[message]']}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          </Field>
          <Field
            name="['fields[name]']"
            validate={value => {
              let error;
              if (value === '') {
                error = '昵称是必须的';
              }
              return error;
            }}
          >
            {({ field, form }) => (
              <FormControl
                my={3}
                isInvalid={errors['fields[name]'] != undefined}
              >
                <FormLabel htmlFor="name">昵称*</FormLabel>
                <Input
                  {...field}
                  id="name"
                  type="text"
                  focusBorderColor="white"
                  errorBorderColor={inputErrorColor}
                  width={['100%', '100%', 'sm']}
                />
                {errors['fields[name]'] && touched['fields[name]'] && (
                  <FormErrorMessage color={inputErrorColor}>
                    {errors['fields[name]']}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
          </Field>
          <Field
            name="['fields[email]']"
            validate={value => {
              let error;
              if (
                value &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
              ) {
                error = '邮件地址不合法';
              }
              return error;
            }}
          >
            {({ field, form }) => (
              <FormControl
                my={3}
                isInvalid={errors['fields[email]'] != undefined}
              >
                <FormLabel htmlFor="email">邮件地址</FormLabel>
                <Input
                  {...field}
                  id="email"
                  type="email"
                  focusBorderColor="white"
                  errorBorderColor={inputErrorColor}
                  width={['100%', '100%', 'sm']}
                />
                {errors['fields[email]'] && touched['fields[email]'] ? (
                  <FormErrorMessage color={inputErrorColor}>
                    {errors['fields[email]']}
                  </FormErrorMessage>
                ) : (
                  <FormHelperText
                    color={colorModeValue(
                      colorMode,
                      'whiteAlpha.800',
                      'blackAlpha.600',
                    )}
                  >
                    匹配您的Gravatar头像
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Field>

          {replyTo && (
            <>
              <Field name="['fields[replyTo]']">
                {({ field, form }) => (
                  <input type="hidden" {...field} data-testid="replyTo" />
                )}
              </Field>
              <Field name="['fields[replyToUser]']">
                {({ field, form }) => <input type="hidden" {...field} />}
              </Field>
            </>
          )}
          <Field name="['fields[postId]']">
            {({ field, form }) => <input type="hidden" {...field} />}
          </Field>
          <Field name="['options[slug]']">
            {({ field, form }) => <input type="hidden" {...field} />}
          </Field>
          <FormControl my={6}>
            <Button
              isLoading={isSubmitting}
              type="submit"
              variant="outline"
              borderRadius="full"
              colorScheme="gray"
              layerStyle="nowContent"
              w={20}
            >
              提交
            </Button>
            {onCancel && (
              <Button
                type="submit"
                variant="outline"
                borderRadius="full"
                colorScheme="gray"
                layerStyle="nowContent"
                mx={4}
                w={20}
                onClick={onCancel}
              >
                取消
              </Button>
            )}
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}
