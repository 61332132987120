import React, {useRef} from 'react';
import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  IconButton,
} from '@chakra-ui/react';
import { WeiboShareButton, WeiboIcon } from 'react-share';

export interface ShareButtonProps {
  title: string;
  url: string;
  image?: string;
}

const ShareButton = ({ title, url, image }: ShareButtonProps) => {
  const weiboShare = useRef<HTMLButtonElement>(null);

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="share"
        icon={<Text className="iconfont icon-share" fontSize="2xl" />}
        borderRadius="full"
        variant="ghost"
        colorScheme="gray"
        _hover={{
          bg: 'now.500',
        }}
        _active={{
          bg: 'now.500',
        }}
      />
      <Portal>
        <MenuList>
          <MenuItem
            as="div"
            icon={<WeiboIcon size={32} />}
            onClick={() => weiboShare.current && weiboShare.current!.click()}
          >
            <WeiboShareButton
              url={url}
              title={title}
              image={image}
              ref={weiboShare}
            >
              分享到微博
            </WeiboShareButton>
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
export default ShareButton;
