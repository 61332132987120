import React from 'react';
import { Box, HStack, Text, Flex } from '@chakra-ui/react';
import PhotoMDXRenderer from './PhotoMDXRenderer';

export interface PhotoPostBodyProps {
  post: GatsbyTypes.ShadowedPostPageQuery['blogPost'];
}

export default function PhotoPostBody({ post }: PhotoPostBodyProps) {
  return (
    <Box pt={[3, 5]} pb={[3, 3]}>
      <Flex whiteSpace="nowrap" flexWrap="wrap" my={8} mx={4}>
        <HStack spacing={4} fontSize="md" color="gray.500" mr={4}>
          <Box as="span">
            <Text
              as="span"
              className="iconfont icon-channel"
              mx={1}
              fontSize="md"
            />
            {post?.category}
          </Box>
          <Box as="span">
            <Text
              as="span"
              className="iconfont icon-date"
              mx={1}
              fontSize="md"
            />
            {post?.date}
          </Box>
          <Text as="span">
            <Text
              as="span"
              className="iconfont icon-time"
              mx={1}
              fontSize="md"
            />
            {post?.timeToRead}mins
          </Text>
        </HStack>
        <Box
          flex="1"
          color="blue.500"
          sx={{ direction: ['ltr', 'rtl'] }}
          fontWeight="semibold"
        >
          <Text as="span" className="iconfont icon-tag" mx={1} fontSize="md" />
          {post?.tags.join(' ')}
        </Box>
      </Flex>

      <PhotoMDXRenderer>{post?.body as string}</PhotoMDXRenderer>
    </Box>
  );
}
