import React, { useEffect, useState } from 'react';
import { IconButton, Text } from '@chakra-ui/react';

const useBacktoTop = () => {
  const [isVisible, setVisible] = useState<Boolean>(false);
  const toggleVisibility = () => {
    if (document.documentElement.scrollTop > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return [isVisible, setVisible];
};

export default function BackToTopButton() {
  const [isVisible] = useBacktoTop();

  return (
    <IconButton
      aria-label="Back To Top"
      visibility={isVisible ? 'visible' : 'hidden'}
      variant="outline"
      pos="sticky"
      bottom={8}
      size="lg"
      borderRadius="full"
      icon={<Text className="iconfont icon-top" fontSize="lg"/>}
      onClick={() => {
        document.documentElement.scrollTop = 0;
      }}
    />
  );
}
