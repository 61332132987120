import React, { useMemo } from 'react';
import { useStaticQuery, graphql, Link as GatsbyLink, navigate} from 'gatsby';
import PhotoPostBody from 'src/components/PhotoPostBody';
import PostBody from 'src/components/PostBody';
import usePrevious from 'src/utils/hooks/usePrevious';
import { useLocation, WindowLocation } from '@gatsbyjs/reach-router';
import {
  Container,
  Heading,
  Box,
  Link,
  Text,
  IconButton,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import ShareButton from 'src/components/ShareButton';
import {
  StaticImage,
  GatsbyImage,
} from 'gatsby-plugin-image';
import { Comments } from 'src/components/Comments';


export interface PostProps {
  data: GatsbyTypes.ShadowedPostPageQuery;
}

export default function Post({ data }: PostProps) {
  const location = useLocation() as WindowLocation<{ referer: string }>;
  const prevLocation = usePrevious(location);
  if (prevLocation) {
    location.state = prevLocation.state;
  }

  const post = data.blogPost;

  let postBody: React.ReactNode;
  switch (post?.category) {
    case 'photography':
      postBody = <PhotoPostBody post={post} />;
      break;
    default:
      postBody = <PostBody post={post} />;
  }
  
  return (
    <>
      <Box display="grid" color="whiteAlpha.800">
        <Box gridArea="1/1" position="relative" placeItems="center">
          {post?.image ? (
            <GatsbyImage
              alt="bg"
              image={post.image!.childImageSharp!.gatsbyImageData}
              style={{ height: '60vh' }}
            />
          ) : (
            <StaticImage
              src="../../assets/images/post-default-bg.jpg"
              alt="bg"
              layout="fullWidth"
              style={{ height: '60vh' }}
            />
          )}
        </Box>
        <Container
          maxW="container.xl"
          pos="relative"
          display="grid"
          gridArea="1/1"
          position="relative"
          placeItems="center"
        >
          <Heading my={4}>{post?.title}</Heading>
          <Box pos="absolute" left={4} bottom={4}>
            {location.state && location.state.referer && (
              <Link
                color="white"
                variant="now"
                as={GatsbyLink}
                to={location.state.referer}
              >
                <Text
                  as="span"
                  className="iconfont icon-back"
                  mx={2}
                  fontSize="md"
                />
                返回
              </Link>
            )}
          </Box>
          <HStack pos="absolute" right={4} bottom={4}>
            <IconButton
              aria-label="comment"
              icon={<Text className="iconfont icon-comment" fontSize="2xl" />}
              borderRadius="full"
              variant="ghost"
              colorScheme="gray"
              _hover={{
                bg: 'now.500',
              }}
              onClick={() => navigate('#comment')}
            />
            <ShareButton
              title={post!.excerpt}
              url={data.site!.siteMetadata!.siteUrl + post!.slug}
              image={
                post?.image
                  ? data.site!.siteMetadata!.siteUrl + post!.image!.publicURL!
                  : undefined
              }
            />
          </HStack>
        </Container>
      </Box>

      {postBody}

      <Comments
        slug={post!.slug}
        postId={post!.id}
        allCommentsJson={data.allCommentsJson}
      />

      <Flex
        flexDirection={['column', 'row']}
        mt={16}
        borderY="1px"
        borderColor="gray.300"
      >
        {data.previous && (
          <LinkBox
            as={VStack}
            p={4}
            bg={useColorModeValue('gray.100', 'gray.900')}
            minH={48}
            flex={['initial', '50%']}
          >
            <Text textAlign="center">上一篇</Text>
            <Heading textAlign="center" fontSize="lg">
              <LinkOverlay href={data.previous.slug}>
                {data.previous.title}
              </LinkOverlay>
            </Heading>
            <Text textAlign="center" fontSize="sm">
              {data.previous.excerpt}
            </Text>
          </LinkBox>
        )}
        {data.next && (
          <LinkBox
            as={VStack}
            borderLeft="1px"
            borderColor="gray.300"
            p={4}
            bg={useColorModeValue('gray.100', 'gray.900')}
            minH={48}
            flex={['initial', '50%']}
          >
            <Text textAlign="center">下一篇</Text>
            <Heading textAlign="center" fontSize="lg">
              <LinkOverlay href={data.next.slug}>{data.next.title}</LinkOverlay>
            </Heading>
            <Text textAlign="center" fontSize="sm">
              {data.next.excerpt}
            </Text>
          </LinkBox>
        )}
      </Flex>
    </>
  );
}

