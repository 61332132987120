import { graphql } from 'gatsby';
import PostPage from '../components/post';

export default PostPage;

export const query = graphql`
  fragment CommentsJsonFields on CommentsJson{
    _id
    message
    name
    email
    date
    replyToUser
  }

  query ShadowedPostPage(
    $id: String!
    $previousId: String
    $nextId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          name
          url
        }
      }
    }
    blogPost(id: { eq: $id }) {
      id
      excerpt
      body
      slug
      title
      tags
      date(formatString: "MMMM DD, YYYY", locale: "zh-CN")
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        publicURL
      }
      imageAlt
      socialImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      tableOfContents
      category
      timeToRead
    }
    previous: blogPost(id: { eq: $previousId }) {
      id
      excerpt
      slug
      title
      date(formatString: "MMMM DD, YYYY")
    }
    next: blogPost(id: { eq: $nextId }) {
      id
      excerpt
      slug
      title
      date(formatString: "MMMM DD, YYYY")
    }
    allCommentsJson(filter: {postId: {eq: $id}, replyTo: {eq: null}}) {
      nodes {
        ...CommentsJsonFields
        comments {
          ...CommentsJsonFields
          comments {
            ...CommentsJsonFields
          }
        }
      }
    }
  }
`;
