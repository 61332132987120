import React from 'react';
import {
  Box,
  Container,
  HStack,
  Text,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import BackToTopButton from './BackToTopButton';
import Toc from './Toc';
import PostMDXRenderer from './PostMDXRenderer';

export interface PostBodyProps {
  post: GatsbyTypes.ShadowedPostPageQuery['blogPost'];
}

export default function PostBody({ post }: PostBodyProps) {
  const showBackToTop = useBreakpointValue({ base: false, md: true });

  return (
    <Container maxW="container.xl" px={[0, 0, 4]} pos="relative">
      <Flex whiteSpace="nowrap" flexWrap="wrap" my={[4, 4, 8]}>
        <HStack spacing={4} fontSize="md" color="gray.500" mr={4}>
          <Box as="span">
            <Text
              as="span"
              className="iconfont icon-channel"
              mx={1}
              fontSize="md"
            />
            {post?.category}
          </Box>
          <Box as="span">
            <Text
              as="span"
              className="iconfont icon-date"
              mx={1}
              fontSize="md"
            />
            {post?.date}
          </Box>
          <Text as="span">
            <Text
              as="span"
              className="iconfont icon-time"
              mx={1}
              fontSize="md"
            />
            {post?.timeToRead}mins
          </Text>
        </HStack>
        <Box
          flex="1"
          color="blue.500"
          sx={{ direction: ['ltr', 'rtl'] }}
          fontWeight="semibold"
        >
          <Text
            as="span"
            className="iconfont icon-tag"
            mx={1}
            fontSize="md"
          />
          {post?.tags.join(' ')}
        </Box>
      </Flex>

      <Flex flexDirection={['column', 'column', 'row']}>
        {post?.tableOfContents?.items && (
          <Box w={['100%', '100%', 72]} pos={['sticky', 'sticky', 'static']} top="0" zIndex="10">
            <Toc items={post?.tableOfContents?.items} />
          </Box>
        )}
        <Box flex="1" px={[4, 4, 8]} overflow="auto">
          <PostMDXRenderer>{post?.body as string}</PostMDXRenderer>
        </Box>
      </Flex>

      {showBackToTop && (
        <Flex direction="column" pos="absolute" top="0" right={-8} h="100%">
          <Box flex="1" />
          <BackToTopButton />
        </Flex>
      )}
    </Container>
  );
}
